.CheckoutFormTitle {
  margin-bottom: 40px;

}

.CheckoutFormButton {
  display: inline-block;
  background-color: rgb(48, 200, 214);
  color: #FFF;
  font-size: 1em;
  line-height: 1;
  text-decoration: none;
  letter-spacing: 0.05em;
  padding: 0.8em 1.7em;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: transparent;
  transition: .3s ease-out;
}

.CheckoutFormButton:hover {
  background-color: rgb(155, 214, 219);
}


.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


.error {
  color: #fa755a;
  margin: 20px 0 20px 0;
}

.CheckoutFormComplete {
  margin: 100px 0 30px 0;
}
